<template>
  <div class="layout" :class="{ full: full }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Layout",
  props: {
    full: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="less" rel="stylesheet/less">
@import "./layout.less";
</style>